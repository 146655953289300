@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/animations.scss';

$appointment-height: 11rem;

.appointment {
  &__add {
    min-height: $appointment-height;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &-button {
      @include button-inactive--opacity;

      &:hover {
        @include button-active--opacity;
      }
    }
  }

  &__time {
    display: flex;
  }

  &__separator {
    border: 0;
    border-bottom: 1px solid darken($white, 5%);
    width: 100%;
    margin-left: 1rem;
  }

  &__card {
    min-height: $appointment-height;
    margin-left: 3rem;
    border-radius: 0.5rem;
    padding: 1.2rem 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    color: $white;

    &-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 50%;
    }

    &-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }

    &--create {
      background-color: $dark-background;
      display: flex;
    }

    &--show {
      color: rgba($booked-color, $active-opacity);
      background: linear-gradient(
        90deg,
        rgba($booked-color, $active-opacity),
        rgba($booked-color, $active-opacity) 8px,
        rgba($booked-color, $inactive-opacity) 0,
        rgba($booked-color, $inactive-opacity)
      );

      &:hover {
        color: darken($white, 5%);
        background-color: rgba($booked-color, $active-opacity);
      }
    }

    &--status {
      background-color: $dark-background;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--confirm {
      @include border-card($danger-color);

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    &--error {
      @include border-card($error-color);

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &:last-of-type &__add,
  &:last-of-type &__card {
    display: none;
  }

  &__actions {
    margin-top: 1rem;

    &-button {
      @include button-inactive--opacity;
      margin-right: 0.5rem;
      display: none;

      &:hover {
        @include button-active--opacity;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__card:hover &__actions-button {
    display: inline;
  }

  &__create-input {
    background-color: $dark-background;
    border: 0;
    border-bottom: 1px solid;
    color: $white;
    font-size: 1.5rem;
    height: 2rem;
    margin-bottom: 0.25rem;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &__status-image {
    margin-right: 2rem;
    animation: rotate 1s linear infinite;
  }

  &__error {
    &-message {
      display: flex;
      flex-direction: column;
    }

    &-close {
      @include button-inactive--opacity;

      align-self: flex-start;
      &:hover {
        @include button-active--opacity;
      }
    }
  }

  &__validation {
    color: $danger-color;
    margin-bottom: 1rem;
  }
}
